import { Swiper, SwiperOptions, Navigation, Lazy, Controller} from 'swiper';

Swiper.use([Navigation, Lazy, Controller]);

export default function(){
    return {
        swiperBig: undefined,

        initProductDetail: function($refs){

            // @ts-ignore
            this.swiperBig = new Swiper($refs.big, {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 0,
                preloadImages: false,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                // Bitte auf False lassen wegen 3D
                allowTouchMove: false,
                lazy: {
                    loadPrevNext: true,
                },
                navigation: {
                    nextEl: $refs.bigNext,
                    prevEl: $refs.bigPrev,
                }
            });

            const swiperPreview = new Swiper($refs.preview, {
                direction: 'horizontal',
                loop: false,
                slidesPerView: 4,
                spaceBetween: 5,
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                },
                slideToClickedSlide: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                navigation: {
                    nextEl: $refs.previewNext,
                    prevEl: $refs.previewPrev,
                },
                breakpoints: {
                    576: {
                        direction: 'vertical',
                    },
                    768: {
                        direction: 'horizontal',
                        slidesPerView: 4
                    },
                    992: {
                        direction: 'vertical',
                        slidesPerView: 6
                    }
                },
                controller: {
                    control: this.swiperBig
                }

            });

        },
        scrollTo: function (index) {
            if (this.swiperBig) {
                // @ts-ignore
                this.swiperBig.slideTo(index)
            }
        }
    }
}
