import Tooltip from 'bootstrap/js/src/tooltip';

export default function () {
    return {
        initTooltip: function ($refs) {
            new Tooltip($refs.tooltip, {
                html: true,
                title: $refs.html
            });
        }
    }
}
