import { Swiper, SwiperOptions, Navigation, Lazy, Scrollbar} from 'swiper';

Swiper.use([Navigation, Lazy, Scrollbar]);

export default function(data){

    return {
        initSlideProducts: function($refs){
            console.log("$refs", $refs.swiper, data);

            let el = $refs.swiper;
            let next = $refs.next;
            let prev = $refs.prev;

            let swiper;

            if(data === 2){
                
                swiper = new Swiper(el, {
                    loop: false,
                    slidesPerView: 1,
                    spaceBetween: 20,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true
                    },
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },                    
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        hide: false,
                        draggable: true
                    }
                });
                
            } else if(data === 4){
                
                swiper = new Swiper(el, {
                    loop: false,
                    slidesPerView: 1,
                    spaceBetween: 20,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 5
                    },
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    breakpoints: {
                        480: {
                            slidesPerView: 2
                        },
                        768: {
                            slidesPerView: 3
                        },
                        992: {
                            slidesPerView: 4
                        }

                    },
                    navigation: {
                        nextEl: next,
                        prevEl: prev,
                    },
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        hide: false,
                        draggable: true
                    }

                });

            } else if(data === 6){
                
                swiper = new Swiper(el, {
                    loop: false,
                    slidesPerView: 1,
                    spaceBetween: 20,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true
                    },
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    breakpoints: {
                        480: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 4
                        },
                        992: {
                            slidesPerView: 6
                        }
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        hide: false,
                        draggable: true
                    }
                });
            } else {
                console.log('data wrong');
            }
        }
    }
}

