import { Swiper, Navigation, Lazy, Scrollbar} from 'swiper';

Swiper.use([Navigation, Lazy, Scrollbar]);

export default function(data){

    return {
        initSlideCategories: function($refs){
            let el = $refs.swiper;
            let next = $refs.next;
            let prev = $refs.prev;
            
            const swiper = new Swiper(el, {
                loop: false,
                slidesPerView: 2,
                spaceBetween: 20,
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                },
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                breakpoints: {
                    480: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 6
                    },
                    1280: {
                        slidesPerView: 8
                    }
                },
                scrollbar: {
                    el: '.swiper-scrollbar',
                    hide: false,
                    draggable: true
                },
                navigation: {
                    nextEl: next,
                    prevEl: prev,
                }
            });

        }
    }
}

