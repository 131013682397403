export default function (data) {
    const {autocompleteUrl, titles, numberOfResults} = data;
    let controller: AbortController;
    return {
        query: "",
        results: [],
        showHover: false,
        input_focus: false,
        searchFn: function () {
            const input = this.query;
            const url = `${autocompleteUrl}?ajax=1&form_key=` + window['FORM_KEY'] + `&q=${encodeURI(input)}`

            if (input.length < 3) {
                this.results = [];
                return;
            }
            if (controller) {
                controller.abort();
            }
            controller = new AbortController();

            fetch(url, {
                method: 'get',
                signal: controller.signal
            }).then(response => response.json()).then(data => {
                //data = data.slice(0, numberOfResults)//suchergebnisse werden gruppiert und darstellung wird angepasst
                const groupedSearch = {};
                for(const resultKey in data) {
                    if (!data.hasOwnProperty(resultKey)) {
                        continue;
                    }
                    const result = data[resultKey];
                    if (!groupedSearch[result.type]) {
                        groupedSearch[result.type] = {
                            title: titles[result.type],
                            html: result.html,
                            empty: result.html ? result.html.match(/(<div[a-z="\- ]*>\s*){2}<\/div>\s*<\/div>/) ? true : false : '',
                            results: []
                        };
                    }
                    groupedSearch[result.type].results.push(result);
                }

                const arrayResults = Object.keys(groupedSearch).map(index => groupedSearch[index]);
                this.results = arrayResults as [];

            })
                .catch(err => {
                    console.warn(err);
                });
        },
        openMobileSearch: function(){
            const openMobileSearchEvent = new CustomEvent("search-mobile-open", {

            });
            window.dispatchEvent(openMobileSearchEvent);
        }
    }
}