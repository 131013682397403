import init from '@frontend/init';
import Account from '@frontend/components/Account';
import Cart from '@frontend/components/Cart';
import Filters from '@frontend/components/Filters';
import Header from '@frontend/components/Header';
import MegaDropDown from '@frontend/components/MegaDropDown';
import Messages from '@frontend/components/Messages';
import MobileMenu from '@frontend/components/MobileMenu';
import Modal from '@frontend/components/Modal';
import SlideProductMedia from '@frontend/components/SlideProductMedia';
import Search from '@frontend/components/Search';
import SearchMobile from '@frontend/components/SearchMobile';
import ShoppingBar from '@frontend/components/ShoppingBar';
import SlideCategories from '@frontend/components/SlideCategories';
import SlideImages from '@frontend/components/SlideImages';
import SlideProducts from '@frontend/components/SlideProducts';
import SlideStage from '@frontend/components/SlideStage';
import Price from '@frontend/components/Price';
import AddToCart from '@frontend/components/AddToCart';
import ServiceOption from '@frontend/components/ServiceOption';
import AddToCartLightbox from '@frontend/components/AddToCartLightbox';

import lazyload from "@frontend/utilities/lazyload";
import { initPrivateData } from "@frontend/utilities/private-data";
import marquee from "@frontend/utilities/marquee";
import "@frontend/utilities/form-key";

import "@frontend/utilities/lightbox";
import "@frontend/utilities/matchheight";
import "@frontend/utilities/data-post";
import dotw_countdown from "@frontend/utilities/dotw_countdown";

init({
    Account,
    Cart,
    dotw_countdown,
    Filters,
    Header,
    MegaDropDown,
    Messages,
    MobileMenu,
    Modal,
    SlideProductMedia,
    Search,
    SearchMobile,
    ShoppingBar,
    SlideCategories,
    SlideImages,
    SlideProducts,
    SlideStage,
    Price,
    AddToCart,
    ServiceOption,
    AddToCartLightbox
});

lazyload.update();
initPrivateData();
marquee();
